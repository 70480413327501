import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly4E2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/4E/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/4E/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/4E/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/4E/4.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1.4,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1.4,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1.4,
        },

        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1.4,
        },



        // {
        //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
        //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
        //   rows: 1,
        //   cols: 1,
        // },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Hindi Diwas
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 4 E Date: 17 September 2024 and
                                    18 September 2024

                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Knowledge is imparting information whereas wisdom and clarity are the patrons of learning and education"

<br/>
        </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    Hindi is not just a language. It is a cultural identity, a way of life and a source of pride for millions.
                                    The students of Class 4E presented their assembly on the topic, ‘Hindi Diwas’ on Tuesday, 17 September
                                    2024 and Wednesday, 18 September 2024 to acknowledge Hindi as the official language. The assembly
                                    began with a thought for the day followed by a speech, emphasising the importance of Hindi Diwas.
                                    Students performed a skit which highlighted the significance of Hindi Diwas and its connection to the
                                    rainbow representing the diversity and vibrancy of languages that connect us all, across different regions of
                                    our country.  <br></br>
                                    An energetic and colourful dance performance by the students uniquely blended dance and language,
                                    celebrating linguistic diversity. The rainbow theme aptly represented inclusiveness, symbolising how Hindi
                                    Diwas unites people from diverse backgrounds. The assembly concluded with an informative quiz, sparking
                                    enthusiasm about the day&#39;s significance. Hindi, like the rainbow, connects people and stands as a symbol of
                                    our cultural heritage and national identity, which we must preserve and pass on to future generations.
                                    <br></br>


                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Hindi is not just a language, it’s the expression of our soul”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly4E2024;